html {
  font-size: 10px;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: white;

  img {
      width: inherit;
      height: inherit;
  }
}

// input[type="text"] {
//   font-size: 16px;
// }

#__next {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

::-webkit-scrollbar {
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px grey;
  border-radius: unset;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: unset;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
